import styled from "styled-components";

export const HeaderPage = styled.div`
* {
    font-family: "Poppins";
  }
.header-container{
    min-height: 70px;
    display: flex;
    align-items: center;
}
.container {
    max-width: 1765px;
    margin: 0 auto;
}
.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    color: #0849ff;
    font-size: 20px !important;
    text-transform: capitalize;
    line-height: inherit;
    font-weight: bold;
    word-wrap: break-word;
    font-size: 21px;
    font-family: 'Source Sans Pro', serif;
    text-decoration: none;
}
.navheader {
    color: #1C1C1C;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
}

.mobileview {
    display: none;
}
.bar-icon {
    background-color: transparent;
    color: #9e9e9e;
}
.bar-icon svg {
    width: 31px;
    height: 45px;
}

@media only screen and (max-width: 575px) {
    .headerlogocontainer img {
        width: 80%;
    }
}
@media only screen and (max-width: 767px) {
    .header-container{
        min-height: 45px;
    }
    .navheader {
        margin-top: 15px;
        margin-left: 13px;
        text-decoration-line: none;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
    }
    .navheader .list-item {
        margin-left: 0px;
    }
    .mobileview {
        display: block;
    }
    .desktopview {
        display: none;
    }
}
`;