import styled from "styled-components";

export const HomePage = styled.div`
  * {
    font-family: "Poppins";
  }
  --body {
    overflow: hidden;
    font-family: "Poppins";
  }
  .container {
    max-width: 1765px;
    margin: 0 auto;
  }
  .banner-content-wrap {
    width: 70%;
  }
  h4,
  p {
    padding: 0;
    margin: 0;
  }
  .padding-0 {
    padding: 0 !important;
  }
  .from-data-experiences {
    width: 649px;
    color: #1c1c1c;
    font-family: "Poppins";
    font-size: 68px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 74px;
  }
  .digital-collections {
    font-family: "Poppins";
    font-size: 28px;
    line-height: 40px;
    letter-spacing: normal;
    color: #1c1c1c;
    margin-top: 30px;
  }
  .banner-inner-img {
    width: 895px;
    height: 513px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .block-platform {
    margin-top: 50px;
  }
  .block-vist {
    margin: 100px auto 130px;
  }
  .block-content {
    width: 623px;
    margin: 50px auto 65px;
    font-weight: 400;
    font-size: 24px;
    color: #1c1c1c;
    line-height: 36px;
  }
  .card-size {
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
    border-radius: 15px;
    box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
  }
  .icon-height {
    height: 108px;
  }
  .icon-detail {
    height: 108px;
    padding-left: 35px;
  }
  .padding-top-17 {
    padding-top: 17px;
  }
  .padding-bottom-0 {
    padding-bottom: 0px;
  }
  .card-title-platform {
    color: #1c1c1c;
    font-size: 32px;
    font-weight: 600;
    font-family: "Lora", serif;
  }
  .card-title-detail {
    color: #3b83ed;
    font-size: 28px;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
  }
  .card-content {
    font-size: 20px;
    height: 100px;
    margin: 5px auto;
    color: #1c1c1c;
  }
  .valued-partner {
    margin: 100px auto 50px;
  }
  .valued-title {
    font-family: Poppins;
    font-size: 32px;
    color: #1c1c1c;
    line-height: 38px;
  }
  .valued-content {
    width: 619px;
    margin: 30px auto 45px;
    font-weight: 400;
    font-size: 20px;
    color: #1c1c1c;
    line-height: 26px;
  }
  .logo-detail {
    border-radius: 15px;
  }
  .card-detail {
    border-radius: 15px;
    box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
  }
  .card-title-vist {
    font-size: 28px;
    font-family: 'Mulish', sans-serif; !important;
    text-align: left;
    line-height: 32px;
    font-weight: 600;
    color: #1c1c1c;
  }
  .card-content-vist {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    width: 345px;
    height: 100px;
    margin: 5px auto;
    color: #1c1c1c;
  }
  .CTA {
    min-height: 150px;
    background-color: #3b83ed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-color-white {
    color: #ffffff;
    margin: 0;
  }
  .bookDemoButton {
    box-sizing: border-box;
    margin-left: 45px;
    height: 63px;
    width: 256px;
    border: 3px solid #ffffff;
    border-radius: 30px;
  }
  .bookDemoButton span:nth-child(1) {
    height: 25px;
    width: 256px;
    color: #f9f9f9;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
  }
  .bookDemoButton:hover {
    border: 3px solid #ff0000;
  }
  .banner-img {
    width: 100%;
    background-size: cover;
    height: 600px;
  }
  .banner-img .container {
    justify-content: space-between;
    align-items: center;
  }
  .ourSolutions {
    height: 40px;
    width: 200px;
    margin-top: 45px;
    border-radius: 50px;
    background-color: #3b83ed;
    color: #ffffff;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1;
    line-height: 28px;
    text-transform: none;
  }
  .ourSolutions:hover {
    background-color: #ffffff;
    color: #3b83ed;
  }
  .mobile-view {
    display: none;
  }
  .mobile-view-who-is-it {
    display: none;
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .mobile-view {
      display: block;
    }
    .mobile-view-who-is-it {
      display: none;
    }
    .desctop-view {
      display: none;
    }
    .banner-img {
      padding: 5% 0;
      width: 100%;
      height: auto;
    }
    .banner-content-wrap {
      width: auto;
    }
    .from-data-experiences {
      width: auto;
    }
    .valued-partner {
      margin: 50px auto 30px;
    }
    .block-vist {
      margin: 50px auto 75px;
    }
    .CTA {
      min-height: 250px;
      flex-direction: column;
    }
    .text-color-white {
      margin: 0 30px 20px;
      text-align: center;
      font-size: 40px;
    }
  }

  @media only screen and (max-width: 767px) {
    .mobile-view {
      display: block;
    }
    .mobile-view-who-is-it {
      display: none;
    }
    .desctop-view {
      display: none;
    }
    .from-data-experiences {
      width: 306px;
      font-size: 32px;
      line-height: 40px;
    }
    .banner-inner-img {
      width: 326px;
      height: 226px;
    }
  }

  @media only screen and (max-width: 575px) {
    .mobile-view {
      display: block;
    }
    .mobile-view-who-is-it {
      display: block;
    }
    .desctop-view {
      display: none;
    }
    .desctop-view-who-is-it {
      display: none;
    }
    .banner-img {
      padding: 3% 0;
      width: 100%;
      height: auto;
    }
    .ourSolutions {
      height: 36px;
      width: 150px;
      margin-top: 25px;
      border-radius: 18px;
      background-color: #3b83ed;
      color: #ffffff;
      font-family: "Poppins";
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1;
      line-height: 17px;
      text-align: center;
      text-transform: none;
    }
    .banner-content-wrap {
      width: auto;
    }
    .block-content {
      width: 100%;
      margin: 18px auto 40px;
      font-size: 16px;
      color: #1c1c1c;
      line-height: 24px;
    }
    .block-platform {
      margin-top: 35px;
    }
    .digital-collections {
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px;
    }
    .icon-height {
      height: 65px;
    }
    .icon-detail {
      height: 65px;
      padding-left: 0px;
      margin-top: 25px;
    }
    .card-title-platform {
      font-size: 23px;
    }
    .card-title-detail {
      font-size: 20px;
    }
    .card-size {
      min-height: 240px;
      border-radius: 9px;
      box-shadow: 0 0 24px 12px rgba(113, 124, 156, 0.1);
    }
    .card-content {
      font-size: 14px;
      height: 60px;
      margin: 5px auto;
    }
    .valued-partner {
      margin: 35px auto 20px;
    }
    .valued-title {
      font-size: 20px;
      line-height: 23px;
    }
    .valued-content {
      width: 100%;
      margin: 10px auto 15px;
      font-size: 14px;
      line-height: 20px;
    }
    .block-vist {
      margin: 20px auto 55px;
    }
    .block-header {
      font-size: 24px;
      line-height: 30px;
    }
    .card-detail {
      flex-direction: column;
      border-radius: 9px;
      box-shadow: 0 0 24px 12px rgba(113, 124, 156, 0.1);
    }
    .padding-top-17 {
      padding-top: 0px;
    }
    .card-title-vist {
      font-size: 18px;
      line-height: 34px;
      text-align: center;
    }
    .card-content-vist {
      font-size: 14px;
      text-align: center;
      width: 100%;
      margin: 5px auto auto;
    }
    .CTA {
      min-height: 250px;
      flex-direction: column;
    }
    .text-color-white {
      margin: 0 30px 20px;
      text-align: center;
      font-size: 32px;
    }
    .bookDemoButton {
      margin-left: 0;
      height: 43px;
      width: 182px;
      border: 2px solid #ffffff;
      border-radius: 30px;
    }
    .bookDemoButton span:nth-child(1) {
      height: 18px;
      font-size: 14px;
      line-height: 17px;
    }
  }
`;
