import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import Button from "@material-ui/core/Button";
import React from "react";
import bannerInnerImg from "../../assets/header-image.svg";
import AiIcon from "../../assets/icon/ai-icon.svg";
import DataIcon from "../../assets/icon/data-icon.svg";
import Education from "../../assets/icon/education-icon.svg";
import Events from "../../assets/icon/events-icon.svg";
import ExperienceIcon from "../../assets/icon/experience-icon.svg";
import Genealogy from "../../assets/icon/genealogy-icon.svg";
import Museums from "../../assets/icon/museums-icon.svg";
import PublishIcon from "../../assets/icon/publish-icon.svg";
import Tourism from "../../assets/icon/tourism-icon.svg";
import anuLogo from "../../assets/logo/anu-logo.png";
import dplaLogo from "../../assets/logo/dpla-logo.png";
import europeanaLogo from "../../assets/logo/europeana-logo.png";
import fooksmanLogo from "../../assets/logo/fooksman-logo.png";
import JHNlohoLogo from "../../assets/logo/JHN-loho.png";
import pangeanicLogo from "../../assets/logo/pangeanic-logo.png";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import { HomePage } from "./Homestyle";

const Home: React.FC = () => {
  return (
    <div className="main">
      <Header />
      <HomePage>
        <div className="banner-img d-flex">
          <div className="container d-flex">
            <IonGrid className="padding-0">
              <IonRow>
                <IonCol className="mobile-view">
                  <div>
                    <img src={bannerInnerImg} className="banner-inner-img" />
                  </div>
                </IonCol>
                <IonCol
                  className="d-flex ion-align-items-center padding-0"
                  size="12"
                  sizeXl="6"
                  sizeMd="12"
                  sizeSm="6"
                >
                  <div>
                    <div className="banner-content-wrap ">
                      <h2 className="from-data-experiences">
                        From Data to Experiences
                      </h2>
                      <p className="digital-collections">
                        Turn your digital collections into powerful storytelling
                        experiences for the digital age
                      </p>
                      <Button
                        className="ourSolutions"
                        variant="contained"
                        color="primary"
                      >
                        Our Solutions
                      </Button>
                    </div>
                  </div>
                </IonCol>
                <IonCol className="desctop-view">
                  <div>
                    <img src={bannerInnerImg} className="banner-inner-img" />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
        <div className="container block-platform">
          <div className="ion-text-center">
            <h2 className="block-header">Our Platform</h2>
            <p className="block-content">
              A comprehensive end-to-end platform to facilitate development of
              experiences from various sources.{" "}
            </p>
          </div>
          <IonRow>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="3">
              <IonCard className="d-flex card-size">
                <img src={DataIcon} alt="Data Icon" className="icon-height" />
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center">
                    <span className="card-title-platform">Vist </span>{" "}
                    <span className="card-title-detail">.data</span>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-text-center card-content">
                  Aggregate and organise data from various sources
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="3">
              <IonCard className="d-flex card-size">
                <img src={AiIcon} alt="Ai Icon" className="icon-height" />
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center">
                    <span className="card-title-platform">Vist </span>{" "}
                    <span className="card-title-detail">.AI</span>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-text-center card-content">
                  AI services to enhance your data
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="3">
              <IonCard className="d-flex card-size">
                <img
                  src={ExperienceIcon}
                  alt="Experience Icon"
                  className="icon-height"
                />
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center">
                    <span className="card-title-platform">Vist </span>{" "}
                    <span className="card-title-detail">.experience</span>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-text-center card-content">
                  Integrated editors to develop various storytelling formats
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="3">
              <IonCard className="d-flex card-size">
                <img
                  src={PublishIcon}
                  alt="Publish Icon"
                  className="icon-height"
                />
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center">
                    <span className="card-title-platform">Vist </span>{" "}
                    <span className="card-title-detail">.publish</span>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-text-center card-content">
                  Publish and embed the results on leading platforms, apps and
                  websites
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </div>
        <div className="container">
          <div className="valued-partner">
            <div className="ion-text-center">
              <h2 className="valued-title">Valued Partners</h2>
              <p className="valued-content">
                Partnering with forward-looking organizations worldwide to
                deliver the next generation of engagement
              </p>
            </div>
            <div className="d-flex ion-justify-content-center ">
              <IonGrid className="padding-0">
                <IonRow>
                  <IonCol size="6" sizeMd="6" sizeXl="2">
                    <img
                      src={europeanaLogo}
                      alt="Europeana's Logo"
                      className="logo-detail"
                    />
                  </IonCol>
                  <IonCol size="6" sizeMd="6" sizeXl="2">
                    <img
                      src={JHNlohoLogo}
                      alt="JHN loho's Logo"
                      className="logo-detail"
                    />
                  </IonCol>
                  <IonCol size="6" sizeMd="6" sizeXl="2">
                    <img
                      src={anuLogo}
                      alt="Anu's Logo"
                      className="logo-detail"
                    />
                  </IonCol>
                  <IonCol size="6" sizeMd="6" sizeXl="2">
                    <img
                      src={fooksmanLogo}
                      alt="Fooksman's Logo"
                      className="logo-detail"
                    />
                  </IonCol>
                  <IonCol size="6" sizeMd="6" sizeXl="2">
                    <img
                      src={dplaLogo}
                      alt="DPLA's Logo"
                      className="logo-detail"
                    />
                  </IonCol>
                  <IonCol size="6" sizeMd="6" sizeXl="2">
                    <img
                      src={pangeanicLogo}
                      alt="pangeanic's Logo"
                      className="logo-detail"
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </div>
        <div className="container block-vist">
          <div className="ion-text-center">
            <h2 className="block-header">Who is vist.ai for?</h2>
            <p className="block-content">
              Find out how Vist.ai can work for your purposes. We will create a
              custom-tailored plan - just for you!
            </p>
          </div>
          <div className="desctop-view-who-is-it">
            <IonRow>
              <IonCol size="12" sizeLg="6">
                <IonCard className="card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Museums}
                    alt="Museums Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Museums and Archives
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Engage and educate using digital heritage collections
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeLg="6">
                <IonCard className=" card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Tourism}
                    alt="Tourism Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Tourism
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      End-to-end production studio for touristic experiences
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeLg="6">
                <IonCard className="card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img src={Events} alt="Events Icon" className="icon-detail" />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Events
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Interactive online events with rich content
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeLg="6">
                <IonCard className="card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Education}
                    alt="Education Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Education
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Connect digital learning environments with heritage
                      collections
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2" sizeLg="3"></IonCol>
              <IonCol size="8" sizeMd="12" sizeLg="6">
                <IonCard className="card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Genealogy}
                    alt="Genealogy Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Genealogy
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Use family photos and documents to create unique family
                      experiences
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol size="2" sizeLg="3"></IonCol>
            </IonRow>
          </div>
          <div className="mobile-view-who-is-it">
            <IonSlides>
              <IonSlide>
                <IonCard className="card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Museums}
                    alt="Museums Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Museums and Archives
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Engage and educate using digital heritage collections
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonSlide>
              <IonSlide>
                <IonCard className=" card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Tourism}
                    alt="Tourism Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Tourism
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      End-to-end production studio for touristic experiences
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonSlide>
              <IonSlide>
                <IonCard className="card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img src={Events} alt="Events Icon" className="icon-detail" />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Events
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Interactive online events with rich content
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonSlide>
              <IonSlide>
                <IonCard className="card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Education}
                    alt="Education Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Education
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Connect digital learning environments with heritage
                      collections
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonSlide>
              <IonSlide>
                <IonCard className="card-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Genealogy}
                    alt="Genealogy Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Genealogy
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Use family photos and documents to create unique family
                      experiences
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonSlide>
            </IonSlides>
          </div>
        </div>
        <div className="CTA">
          <h2 className="text-color-white">Let us show you what we've got!</h2>
          <Button className="bookDemoButton" variant="outlined" color="primary">
            BOOK A DEMO
          </Button>
        </div>
      </HomePage>
      <Footer />
    </div>
  );
};

export default Home;
