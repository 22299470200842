import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import Instagram from "../assets/icon/instagram-icon.svg";
import Twitter from "../assets/icon/twitter-icon.svg";
import { FooterPage } from "./Footerstyle";

const Footer = () => {
  return (
    <FooterPage>
      <div className="d-flex ion-align-items-center ion-justify-content-center footer">
        <div className="container">
          <IonGrid className="padding-0">
            <IonRow>
              <IonCol
                size="12"
                sizeSm="6"
                sizeLg="3"
                className="border-area padding-right-50"
              >
                <h4 className="footer-title">Get in Touch</h4>
                <p className="footer-detail">
                  We would love to find the perfect solution for your needs,
                  shoot us an email at <br />{" "}
                  <a href="#" className="info-link">
                    {" "}
                    info@vist.ai
                  </a>
                </p>
              </IonCol>
              <IonCol
                size="12"
                sizeSm="6"
                sizeLg="3"
                className="border-area padding-left-50"
              >
                <h4 className="footer-title">Follow Us</h4>
                <p className="footer-detail">
                  For all the most recent product updates
                </p>
                <a href="#" className="icon-margin">
                  <img src={Twitter} alt="Twitter" />
                </a>
                <a href="">
                  <img src={Instagram} alt="Instagram" />
                </a>
              </IonCol>
              <IonCol
                size="12"
                sizeSm="6"
                sizeLg="3"
                className=" d-flex padding-left-50"
              >
                <h4 className="footer-title font-color">Platform</h4>
                <span className="list">
                  <div>Vist Data</div>
                  <div>Vist AI</div>
                  <div>Vist Experience</div>
                  <div>Vist Publish</div>
                </span>
              </IonCol>
              <IonCol size="12" sizeSm="6" sizeLg="3" className="d-flex">
                <h4 className="footer-title font-color">Solutions</h4>
                <span className="list">
                  <div>Museums and Archives</div>
                  <div> Tourism </div>
                  <div> Events </div>
                  <div> Education </div>
                  <div> Genealogy</div>
                </span>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container d-flex ion-justify-content-center responsive">
          <div className="copy">Vist ai © All Rights Reserved</div>
        </div>
      </div>
    </FooterPage>
  );
};

export default Footer;
