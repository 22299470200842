import styled from "styled-components";

export const SolutionPage = styled.div`
  --body {
    overflow: hidden;
    font-family: "Poppins";
  }
  .container {
    max-width: 1765px;
    margin: 0 auto;
  }
  .banner-content-wrap {
    width: 70%;
  }
  h4,
  p {
    padding: 0;
    margin: 0;
  }
  .padding-0 {
    padding: 0 !important;
  }
  .from-data-experiences {
    width: 649px;
    color: #1c1c1c;
    font-family: "Poppins";
    font-size: 68px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 74px;
  }
  .digital-collections {
    font-family: "Poppins";
    font-size: 28px;
    font-weight: Regular;
    line-height: 40px;
    letter-spacing: normal;
    color: #1c1c1c;
    margin-top: 30px;
  }
  .banner-inner-img {
    width: 895px;
    height: 513px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .block-platform {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .block-vist {
    margin: 47px auto 85px;
  }
  .text-content {
    width: 756px;
    margin: 20px auto 25px;
    font-weight: 400;
    font-size: 28px;
    color: #1c1c1c;
    line-height: 40px;
    font-family:Poppins;
    text-align: center;
  }
  .card-content {
      width:297px
      color:#1C1C1C;
      font-family: Poppins;
      font-size: 20px;
      Line height:26px;
      text-align: center;
      Letter: 2.13px;
      margin: 5px auto;
      Letter:normal;
      font-weight: 200;
      text-align: center;
      height: 101px;
      padding: 0px 30px;
  }
  .card-size {
    flex-direction: column;
    justify-content: center;
    height: 400px;
    width: 373px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
  }
  .card-spacing{
  margin: 13px 183px;
  }
  .card-pic{
    justify-content: center;
    height:265px;
    width: 622px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
  }
  .card-title-platform {
    color: #1c1c1c;
    font-size: 28px;
    Line height:68px;
    font-family: Lora;
    Letter: 2.13px;
    font-weight: bold;
  }
  .card-title-detail {
    color: #3b83ed;
    font-size: 28px;
    Line height:68px;
    font-family: Mulish;
    Letter:Letter: -1.06px;
    font-weight: bold;
  }
  .card-text {
    height: 73px;
    width: 942px;
    color: #1E1E1E;
    font-family: Poppins;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 74px;
    text-align: center;
  }
  
  .span-title-platform {
    color: #1c1c1c;
    font-size: 68px;
    Line height:68px;
    font-family: Lora;
    Letter: 2.13px;
    font-weight: bold;
  }
  .span-title-detail {
    color: #3b83ed;
    font-size: 68px;
    Line height:68px;
    font-family: Mulish;
    Letter:Letter: -1.06px;
    font-weight: bold;
  }
  .line-2 {
    box-sizing: border-box;
    height: 1px;
    border: 2px solid #3B83ED;
  }
  .text-content2 {
    height: 40px;
    width: 100%;
    color: #3B83ED;
    margin: 20px auto 45px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
  }
  .valued-partner {
    margin: 100px auto 50px;
  }
  .valued-title {
    font-family: Poppins;
    font-size: 32px;
    color: #1c1c1c;
    line-height: 38px;
  }
  .valued-content {
    width: 619px;
    margin: 30px auto 45px;
    font-weight: 400;
    font-size: 20px;
    color: #1c1c1c;
    line-height: 26px;
  }
  .logo-radius {
    border-radius: 15px;
  }
  .card-block-left {
    border-radius: 35px;
    margin-left: 49px;
    // box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
    margin-top: 40px;
    display:flex;
  }
  .card-icon{
    height:108px
  }
  .card-block-right {
    border-radius: 25px;
    margin-right: 49px;
    // box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
    margin-top: 30px;
    display:flex;
  }
  .card-block-center {
    min-height: 200px;
    border-radius: 15px;
    margin: 10px 469px;
    box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
  }
  .card-title-vist {
    font-size: 28px;
    font-family: 'Mulish', sans-serif; !important;
    line-height: 32px;
    font-weight: 600;
    color: #1c1c1c;
  }
  .cards-title-vist {

  width: 364px;
  color: #1C1C1C;
  font-family: Poppins;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.44px;
  line-height: 32px;
  margin-left: 38px;
  }
  .cards-content-vist {
    margin-left: 38px;
    height: 120px;
    width: 460px;
    color: #1C1C1C;
    font-family: Poppins;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
  }
  .CTA {
    min-height: 150px;
    background-color: #3b83ed;
  }
  .text-color-white {
    color: #ffffff;
  }
  .bookDemoText{
        height: 73px;
        width: 555px;
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 74px;
        text-align: center;
        margin:20px auto;
  }
  .bookDemoButton {
    box-sizing: border-box;
    margin-left: 45px;
    height: 63px;
    width: 256px;
    border: 3px solid #ffffff;
    border-radius: 30px;
    margin:10px auto;
  }
  .bookDemoButton span:nth-child(1) {
    height: 25px;
    width: 256px;
    color: #f9f9f9;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
  }
  .bookDemoButton:hover {
    border: 3px solid #ff0000;
  }
  .banner-img {
    width: 100%;
    background-size: cover;
    height: 600px;
  }
  .banner-img .container {
    justify-content: space-between;
    align-items: center;
  }
  .book-demo-flex{
    display:flex;
    flex-direction: column;
    height:295px;
  }
  .ourSolutions {
    height: 40px;
    width: 200px;
    margin-top: 45px;
    border-radius: 50px;
    background-color: #3b83ed;
    color: #ffffff;
    font-family: "Poppins";
    font-size: 20 px;
    font-weight: bold;
    letter-spacing: 1;
    line-height: 28px;
    text-transform: none;
  }
  .ourSolutions:hover {
    background-color: #3b83ed;
    color: #ffffff;
  }

  .pic-card{
    margin: 20px 16%;
  }
  .desktop-view {
    display: none;
  }
  @media only screen and (max-width: 1440px) {
    .card-spacing{
      margin: 13px 58px;
      }
      .cards-title-vist {
        margin-left: 0px;
        width: 100%;
        color: #1C1C1C;
        font-family: Poppins;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: -0.44px;
        line-height: 32px;
        text-align:center;
        }
        .cards-content-vist {
          margin-left: 0px;
          width: 100%;
          color: #1C1C1C;
          font-family: Poppins;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 30px;
          height:auto;
          text-align:center;
        }
        .card-pic{
          justify-content: center;
          height:200px;
          width: 622px;
          text-align: center;
          border-radius: 15px;
          box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
        }
  }
  @media only screen and (max-width: 1280px){
    .card-spacing{
      margin: 13px 0px;
      }
  }
  @media only screen and (max-width: 1024px){
    .pic-card{
      margin: 20px 4%;
    }
    .card-spacing{
      margin: 13px 58px;
      }
  }
  @media only screen and (max-width: 820px){
    .card-spacing{
      margin: 13px 20px;
      }
      .pic-card{
          margin: 20px 15%;
        }
        .card-block-left {
          border-radius: 35px;
          display: block ;
          margin-left:0px;
          margin-top:5px;
        }
        .card-block-right {
          border-radius: 25px;
          display:block;
          margin-right:0px;
          margin-top:5px;
        }
        .text-center{
          text-align:center;
        }
      .cards-title-vist {
        margin-left: 0px;
        width: 100%;
        color: #1C1C1C;
        font-family: Poppins;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: -0.44px;
        line-height: 32px;
        text-align:center;
        }
        .cards-content-vist {
          margin-left: 0px;
          width: 100%;
          color: #1C1C1C;
          font-family: Poppins;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 30px;
          height:auto;
          text-align:center;
        }
        .mobile-view {
          display: none;
        }
        .desktop-view {
          display: block;
        }
        .span-title-platform {
          color: #1c1c1c;
          font-size: 48px;
          Line height:68px;
          font-family: Lora;
          Letter: 2.13px;
          font-weight: bold;
        }
        .span-title-detail {
          color: #3b83ed;
          font-size: 48px;
          Line height:68px;
          font-family: Mulish;
          Letter:Letter: -1.06px;
          font-weight: bold;
        }
        .text-content {
          width: 100%;
          margin: 20px auto 25px;
          font-weight: 400;
          font-size: 23px;
          color: #1c1c1c;
          line-height: 40px;
          font-family:Poppins;
          text-align: center;
        }
        .text-content2 {
          height: 40px;
          width: 100%;
          color: #3B83ED;
          margin: 20px auto 45px;
          font-family: Poppins;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 40px;
          text-align: center;
        }
        .card-pic{
          justify-content: center;
          height:235px;
          width: 622px;
          text-align: center;
          border-radius: 15px;
          box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
        }
          .card-size {
            flex-direction: column;
            justify-content: center;
            height: 400px;
            width: 100%;
            border-radius: 15px;
            box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
            margin : 5px 5px;
          }
  }
  @media only screen and (max-width: 768px) {
    .card-spacing{
      margin: 13px 20px;
      }
      .pic-card{
          margin: 20px 15%;
        }
        .card-block-left {
          border-radius: 35px;
          display: block ;
          margin-left:0px;
          margin-top:5px;
        }
        .card-block-right {
          border-radius: 25px;
          display:block;
          margin-right:0px;
          margin-top:5px;
        }
        .text-center{
          text-align:center;
        }
      .cards-title-vist {
        margin-left: 0px;
        width: 100%;
        color: #1C1C1C;
        font-family: Poppins;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: -0.44px;
        line-height: 32px;
        text-align:center;
        }
        .cards-content-vist {
          margin-left: 0px;
          width: 100%;
          color: #1C1C1C;
          font-family: Poppins;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 30px;
          height:auto;
          text-align:center;
        }
        .mobile-view {
          display: none;
        }
        .desktop-view {
          display: block;
        }
        .span-title-platform {
          color: #1c1c1c;
          font-size: 48px;
          Line height:68px;
          font-family: Lora;
          Letter: 2.13px;
          font-weight: bold;
        }
        .span-title-detail {
          color: #3b83ed;
          font-size: 48px;
          Line height:68px;
          font-family: Mulish;
          Letter:Letter: -1.06px;
          font-weight: bold;
        }
        .text-content {
          width: 100%;
          margin: 20px auto 25px;
          font-weight: 400;
          font-size: 23px;
          color: #1c1c1c;
          line-height: 40px;
          font-family:Poppins;
          text-align: center;
        }
        .text-content2 {
          height: 40px;
          width: 100%;
          color: #3B83ED;
          margin: 20px auto 45px;
          font-family: Poppins;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 40px;
          text-align: center;
        }
        .card-pic{
          justify-content: center;
          height:235px;
          width: 622px;
          text-align: center;
          border-radius: 15px;
          box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
        }
          .card-size {
            flex-direction: column;
            justify-content: center;
            height: 400px;
            width: 100%;
            border-radius: 15px;
            box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
          }
      }
  @media only screen and (max-width: 425px) {
    .span-title-platform {
      color: #1c1c1c;
      font-size: 38px;
      Line height:38px;
      font-family: Lora;
      Letter: 2.13px;
      font-weight: bold;
    }
    .span-title-detail {
      color: #3b83ed;
      font-size: 38px;
      Line height:38px;
      font-family: Mulish;
      Letter:Letter: -1.06px;
      font-weight: bold;
    }
    .text-content {
      margin: 20px auto 25px;
      font-weight: 400;
      font-size: 16px;
      color: #1c1c1c;
      line-height: 30px;
      font-family:Poppins;
      text-align: center;
    }
    .text-content2 {
      height: 40px;
      width: 100%;
      color: #3B83ED;
      margin: 20px auto 45px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
    }
    .card-pic{
      justify-content: center;
      height:142px;
      width: 622px;
      text-align: center;
      border-radius: 15px;
      box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
    }
    .cards-title-vist {
      width: 100%;
      color: #1C1C1C;
      font-family: Poppins;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.44px;
      line-height: 32px;
      
      }
      .cards-content-vist {
        height: auto;
        width: 100%;
        color: #1C1C1C;
        font-family: Poppins;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 25px;
      }
      .pic-card{
        margin: 20px 8%;
      }
      .bookDemoText{
        height: 2px;
        width: 100%;
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 2px;
        text-align: center;
        margin:30px auto;
     }
     .book-demo-flex{
      display:flex;
      flex-direction: column;
      height:190px;
    }
    .card-text {
      height: 73px;
      width: 100px;
      color: #1E1E1E;
      font-family: Poppins;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 54px;
      text-align: center;
    }
    .card-icon{
      height:100px
    }
    .card-title-detail {
      color: #3b83ed;
      font-size: 20px;
      Line height:68px;
      font-family: Mulish;
      Letter:Letter: -1.06px;
      font-weight: bold;
    }
    .card-title-platform {
      color: #1c1c1c;
      font-size: 20px;
      Line height:68px;
      font-family: Lora;
      Letter: 2.13px;
      font-weight: bold;
    }
    .card-content {
      width:100%
      color:#1C1C1C;
      font-family: Poppins;
      font-size: 16px;
      Line height:26px;
      text-align: center;
      Letter: 2.13px;
      margin: 5px auto;
      Letter:normal;
      font-weight: 200;
      text-align: center;
      height: 101px;
      padding: 0px 30px;
  }
  .card-size {
    flex-direction: column;
    justify-content: center;
    height: 350px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
    margin:15px 0px;
  }
  .bookDemoButton span:nth-child(1) {
    height: 25px;
    width: 256px;
    color: #f9f9f9;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
  }
  .block-vist {
    margin: 47px auto;
  }
  }
  @media only screen and (max-width: 375px) 
  {
    .card-size {
      flex-direction: column;
      justify-content: center;
      height: 300px;
      width: 100%;
      border-radius: 15px;
      box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
    }
    .card-content {
      width:100%
      color:#1C1C1C;
      font-family: Poppins;
      font-size: 16px;
      Line height:26px;
      text-align: center;
      Letter: 2.13px;
      margin: 5px auto;
      Letter:normal;
      font-weight: 200;
      text-align: center;
      height: 101px;
      padding: 0px 30px;
    }
  }
  @media only screen and (max-width: 320px) 
  {
    .card-content {
       width:100%
       color:#1C1C1C;
       font-family: Poppins;
       font-size: 14px;
       Line height:26px;
       text-align: center;
       Letter: 2.13px;
       margin: 5px auto;
       Letter:normal;
       font-weight: 200;
       text-align: center;
       height: 101px;
          padding: 0px 30px;
        }
        .card-size {
        flex-direction: column;
        justify-content: center;
        height: 300px;
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
        }
        .card-icon{
          height:88px
        }
        .text-content {
          margin: 20px auto 25px;
          font-weight: 400;
          font-size: 14px;
          color: #1c1c1c;
          line-height: 30px;
          font-family:Poppins;
          text-align: center;
        }
        .text-content2 {
          height: 40px;
          width: 100%;
          color: #3B83ED;
          margin: 20px auto 45px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 30px;
          text-align: center;
        }
        .cards-content-vist {
          height: auto;
          width: 100%;
          color: #1C1C1C;
          font-family: Poppins;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 25px;
        }
        .pic-card{
          margin: 20px 8%;
        }
        .bookDemoText{
          height: 2px;
          width: 100%;
          color: #FFFFFF;
          font-family: Poppins;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 2px;
          text-align: center;
          margin:30px auto;
       }
       .card-content {
        width:100%
        color:#1C1C1C;
        font-family: Poppins;
        font-size: 14px;
        Line height:26px;
        text-align: center;
        Letter: 2.13px;
        margin: 5px auto;
        Letter:normal;
        font-weight: 200;
        text-align: center;
        height: 101px;
        padding: 0px 30px;
    }
    .bookDemoButton span:nth-child(1) {
      height: 25px;
      width: 256px;
      color: #f9f9f9;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 28px;
      text-align: center;
      text-transform: capitalize;
    }
  }
`;
