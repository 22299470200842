import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import Button from "@material-ui/core/Button";
import React from "react";
import Education from "../../assets/icon/education-icon.svg";
import Events from "../../assets/icon/events-icon.svg";
import Genealogy from "../../assets/icon/genealogy-icon.svg";
import Museums from "../../assets/icon/museums-icon.svg";
import Tourism from "../../assets/icon/tourism-icon.svg";
import IntegratedText from "../../assets/images/integrated-text.png";
import Integrated from "../../assets/images/integrated.png";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import { ProductPage } from "./Productstyle";

const Product: React.FC = () => {
  return (
    <div className="main">
      <Header />
      <ProductPage>
        <div className="container block-museums ion-text-center">
          <img src={Museums} alt="Museums Icon" className="museums-icon" />
          <div className="vist-museums">
            <span className="font-lora">Vist</span> for Museums and Archives
          </div>
          <div className="museums-detail">
            <div className="museums-description-1">
              {" "}
              Turn your digitised collections and metadata into powerful
              contemporary experiences for visitors, students and tourists.
            </div>
            <br />

            <div className="museums-description-2">
              {" "}
              Choose the type of experience matching your audience – guided
              tour, digital story, or, perhaps a virtual 3D space? – and quickly
              populate it with items from your collections already fetched into
              the system using one of our readymade data connectors.
            </div>
          </div>
        </div>
        <div className="container how-work">
          <div className="block-header">How Does it Work?</div>
          <IonGrid className="block-work-grid">
            <IonRow>
              <IonCol size="12" sizeXl="1"></IonCol>
              <IonCol size="12" sizeXl="7">
                <IonCard className="d-flex card-detail ion-align-items-center">
                  <div className="card-number">01</div>
                  <div className="work-card-header">
                    <div className="work-card-title">Ingest</div>
                    <div className="work-card-description">
                      Ingest your entire collection with a few simple
                      configurations using our set of data pipelines for all the
                      popular CMS systems (Adlib, Ex Libris etc.) and data
                      formats (DC, MARC, METS)
                    </div>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeXl="4"></IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeXl="4"></IonCol>
              <IonCol size="12" sizeXl="7">
                <IonCard className="d-flex card-detail ion-align-items-center">
                  <div className="card-number">02</div>
                  <div className="work-card-header">
                    <div className="work-card-title">Organise</div>
                    <div className="work-card-description">
                      Use the powerful semantic search and recommendations to
                      create user sets to power each specific experience (a
                      story or a tour)
                    </div>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeXl="1"></IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeXl="1"></IonCol>
              <IonCol size="12" sizeXl="7">
                <IonCard className="d-flex card-detail ion-align-items-center">
                  <div className="card-number">03</div>
                  <div className="work-card-header">
                    <div className="work-card-title">Create Experience</div>
                    <div className="work-card-description">
                      Use the creator's dashboard to choose the wanted type of
                      experience and edit it experience using the designated
                      editor
                    </div>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeXl="4"></IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeXl="4"></IonCol>
              <IonCol size="12" sizeXl="7">
                <IonCard className="d-flex card-detail ion-align-items-center">
                  <div className="card-number">04</div>
                  <div className="work-card-header">
                    <div className="work-card-title">Populate</div>
                    <div className="work-card-description">
                      Populate it with the items from the data sets
                    </div>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeXl="1"></IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeXl="1"></IonCol>
              <IonCol size="12" sizeXl="7">
                <IonCard className="d-flex card-detail ion-align-items-center">
                  <div className="card-number">05</div>
                  <div className="work-card-header">
                    <div className="work-card-title">Publish</div>
                    <div className="work-card-description">
                      Off you go! Publish the experience on our platform,
                      another popular platform of your choice of your own
                      website
                    </div>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol sizeXl="4"></IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className="container">
          <div className="block-header">Integrated Features</div>
          <IonGrid className="grid-margin">
            <IonRow>
              <IonCol size="12" sizeXl="1"></IonCol>
              <IonCol size="12" sizeXl="4" className="integrated-col-img">
                <img
                  src={Integrated}
                  alt="Integrated image"
                  className="integrated-img"
                />
              </IonCol>
              <IonCol size="12" sizeXl="4" className="integrated-description">
                {" "}
                <div className="integrated-title">Semantic Enrichment</div>
                <div className="integrated-detail">
                  Use public authority files to add information to your records
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeXl="1"></IonCol>
              <IonCol size="12" sizeXl="4" className="integrated-col-img">
                <img
                  src={Integrated}
                  alt="Integrated image"
                  className="integrated-img"
                />
              </IonCol>
              <IonCol size="12" sizeXl="4" className="integrated-description">
                {" "}
                <div className="integrated-title">Machine Translation</div>
                <div className="integrated-detail">
                  Translate all the incoming text metadata fields
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeXl="1"></IonCol>
              <IonCol size="12" sizeXl="4" className="integrated-col-img">
                <img
                  src={IntegratedText}
                  alt="Integrated Text image"
                  className="integrated-img"
                />
              </IonCol>
              <IonCol size="12" sizeXl="4" className="integrated-description">
                {" "}
                <div className="integrated-title">IIIF Support</div>
                <div className="integrated-detail">
                  Transforming all incoming digital assets to IIIF industry
                  standard
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" sizeXl="1"></IonCol>
              <IonCol size="12" sizeXl="4" className="integrated-col-img">
                <img
                  src={Integrated}
                  alt="Integrated image"
                  className="integrated-img"
                />
              </IonCol>
              <IonCol size="12" sizeXl="4" className="integrated-description">
                {" "}
                <div className="integrated-title">Share User Sets</div>
                <div className="integrated-detail">
                  Across your organisations with colleagues and friends
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div className="CTA">
          <h2 className="text-color-white">Let us show you what we've got!</h2>
          <Button className="bookDemoButton" variant="outlined" color="primary">
            BOOK A DEMO
          </Button>
        </div>
        <div className="container different-solution">
          <div className="block-solutions-header">
            Looking for a Different Solution?
          </div>

          <div className="desctop-view-who-is-it">
            <IonRow>
              <IonCol size="12" sizeLg="6">
                <IonCard className="card-diff-sol-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Tourism}
                    alt="Tourism Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Tourism
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      End-to-end production studio for touristic experiences
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonCol>

              <IonCol size="12" sizeLg="6">
                <IonCard className=" card-diff-sol-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Genealogy}
                    alt="Genealogy Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Genealogy
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      End-to-end production studio for touristic experiences
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonCol>

              <IonCol size="12" sizeLg="6">
                <IonCard className="card-diff-sol-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img src={Events} alt="Events Icon" className="icon-detail" />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Events
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Interactive online events with rich content
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonCol>

              <IonCol size="12" sizeLg="6">
                <IonCard className="card-diff-sol-detail d-flex ion-align-items-center ion-justify-content-center">
                  <img
                    src={Education}
                    alt="Education Icon"
                    className="icon-detail"
                  />
                  <div className="padding-top-17">
                    <IonCardHeader className="padding-bottom-0">
                      <IonCardTitle className="card-title-vist">
                        Education
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="card-content-vist">
                      Connect digital learning environments with heritage
                      collections
                    </IonCardContent>
                  </div>
                </IonCard>
              </IonCol>
            </IonRow>
          </div>
        </div>
      </ProductPage>
      <Footer />
    </div>
  );
};

export default Product;
