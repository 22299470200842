import styled from "styled-components";

export const ProductPage = styled.div`
  * {
    font-family: "Poppins";
  }
  --body {
    overflow: hidden;
    font-family: "Poppins";
  }
  .container {
    max-width: 1765px;
    margin: 0 auto;
  }
  .block-museums {
    margin: 60px auto;
  }
  .museums-icon {
    height: 130px;
  }
  .vist-museums {
    font-size: 48px;
    line-height: 74px;
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
  }
  .font-lora {
    font-family: "Lora", serif;
  }
  .museums-detail {
    font-size: 28px;
    line-height: 40px;
    width: 65%;
    margin: auto;
  }
  .museums-description-1 {
    font-weight: bold;
    color: #3b83ed;
  }
  .museums-description-2 {
    color: #1c1c1c;
  }
  .how-work {
    margin: 100px auto 200px;
  }
  .block-header {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: #3b83ed;
  }
  .block-work-grid {
    margin-top: 40px;
  }
  .card-detail {
    border-radius: 15px;
    box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
  }
  .card-number {
    min-height: 180px;
    min-width: 160px;
    text-align: center;
    margin: 0px;
    padding: 60px 35px;
    font-size: 64px;
    line-height: 64px;
    color: #ffffff;
    background-color: #3b83ed;
  }
  .work-card-header {
    padding: 0px 20px 0px 65px;
  }
  .work-card-title {
    font-size: 28px;
    padding-bottom: 10px;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    text-align: left;
    color: #1c1c1c;
  }
  .work-card-description {
    font-size: 20px;
    text-align: left;
    color: #1c1c1c;
  }
  .grid-margin {
    margin: 90px auto 150px;
  }
  .integrated-col-img {
    padding: 10px 0;
  }
  .integrated-img {
    border-radius: 15px;
    height: 305px;
    width: 622px;
    box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
  }
  .integrated-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px 10px 120px;
  }
  .integrated-title {
    font-size: 28px;
    font-weight: bold;
    text-align: left;
  }
  .integrated-detail {
    min-width: 460px;
    font-size: 20px;
  }
  .CTA {
    min-height: 295px;
    background-color: #3b83ed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text-color-white {
    color: #ffffff;
    margin: 0px 0px 20px;
    text-align: center;
  }
  .bookDemoButton {
    box-sizing: border-box;
    height: 63px;
    width: 256px;
    border: 3px solid #ffffff;
    border-radius: 30px;
  }
  .bookDemoButton span:nth-child(1) {
    height: 25px;
    width: 256px;
    color: #f9f9f9;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
  }
  .bookDemoButton:hover {
    border: 3px solid #ff0000;
  }
  .different-solution {
    margin: 85px auto 100px;
  }
  .block-solutions-header {
    color: #1e1e1e;
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: bold;
    line-height: 74px;
    text-align: center;
  }
  .card-diff-sol-detail {
    border-radius: 15px;
    box-shadow: 0 0 40px 20px rgba(113, 124, 156, 0.1);
  }
  .icon-detail {
    height: 108px;
    padding-left: 35px;
  }
  .padding-top-17 {
    padding-top: 17px;
  }
  .padding-bottom-0 {
    padding-bottom: 0px;
  }
  .card-title-vist {
    font-size: 28px;
    font-family: 'Mulish', sans-serif; !important;
    text-align: left;
    line-height: 32px;
    font-weight: 600;
    color: #1c1c1c;
  }
  .card-content-vist {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    width: 345px;
    height: 100px;
    margin: 5px auto;
    color: #1c1c1c;
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .text-color-white {
      margin: 0 30px 20px;
      font-size: 40px;
    }
    .work-card-header {
      padding: 10px 20px 10px 65px;
    }
    .card-number {
      min-height: 215px;
      padding: 75px 35px;
    }
    .how-work {
      margin: 75px auto 100px;
    }
    .integrated-img {
      width: 100%;
    }
    .integrated-description {
      padding: 10px 0px;
    }
  }

  @media only screen and (max-width: 575px) {
    ion-grid, ion-col{ 
      padding: 0px !important;
    }
    .block-museums {
      margin: 30px auto;
    }
    .vist-museums {
      font-size: 32px;
      line-height: 40px;
    }
    .museums-icon {
      height: 100px;
    }
    .museums-detail {
      text-align: left;
      width: 100%;
      font-size: 22px;
      line-height: 35px;
    }
    .how-work {
      margin: 50px auto 75px;
    }
    .block-header {
      font-size: 32px;
    }
    .block-work-grid {
      margin-top: 20px;
    }
    .card-detail {
      flex-direction: column;
      margin: 10px 0px;
    }
    .grid-margin {
      margin: 60px auto 100px;
    }
    .integrated-title {
      text-align: center;
    }
    .card-number{
      min-width: 100%;
    }
    .work-card-header {
      padding: 10px;
    }
    .integrated-description {
      padding: 0px;
    }
    .integrated-detail {
      min-width: 100%;
      text-align: center;
    }
    .text-color-white {
      margin: 0px 0px 20px;
      font-size: 32px;
    }
    .bookDemoButton {
      margin-left: 0;
      height: 43px;
      width: 182px;
      border: 2px solid #ffffff;
    }
    .bookDemoButton span:nth-child(1) {
      height: 18px;
      font-size: 14px;
      line-height: 17px;
    }
    .card-diff-sol-detail {
      flex-direction: column;
      border-radius: 9px;
      box-shadow: 0 0 24px 12px rgba(113, 124, 156, 0.1);
    }
    .icon-detail {
      height: 65px;
      padding-left: 0px;
      margin-top: 25px;
    }
    .padding-top-17 {
      padding-top: 0px;
    }
    .card-title-vist {
      font-size: 18px;
      line-height: 34px;
      text-align: center;
    }
    .card-content-vist {
      font-size: 14px;
      text-align: center;
      width: 100%;
      margin: 5px auto auto;
    }
  }
`;
