import styled from "styled-components";

export const FooterPage = styled.div`
  * {
    font-family: "Poppins";
  }
  .container {
    max-width: 1765px;
    margin: 0 auto;
  }
  .footer {
    min-height: 250px;
    background-color: #eeeeee;
  }
  .footer-copyright {
    min-height: 60px;
    background-color: #1e1e1e;
    color: #eeeeee;
    display: flex;
    align-items: center;
    margin-top: auto;
    bottom: 0;
    width: 100%;
  }
  .border-area {
    border-right: #3b83ed 3px solid;
  }
  .padding-right-50 {
    padding-right: 50px;
  }
  .padding-left-50 {
    padding-left: 50px;
  }
  .footer-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
  }
  .footer-detail {
    font-size: 18px;
    font-weight: regular;
    line-height: 26px;
    text-align: left;
  }
  .info-link {
    text-decoration: none;
    font-weight: bold;
  }
  .icon-margin {
    margin-right: 25px;
  }
  .font-color {
    color: #3b83ed;
  }
  .list {
    font-size: 16px;
    line-height: 28px;
    padding: 16px 0 0 25px;
    color: #545454;
  }

  @media only screen and (max-width: 767px) {
    .footer-copyright {
      font-size: 12px;
      line-height: 31px;
      letter-spacing: 0.33px;
    }
    .responsive {
      flex-direction: column;
      align-items: center;
    }
    .border-area {
      border-bottom: #3b83ed 3px solid;
    }
  }

  @media only screen and (max-width: 768px) {
    .padding-left-50 {
      padding-left: 0px;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .border-area {
      border-right: 0px;
    }
  }
`;
