import { IonApp } from "@ionic/react";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import GlobalStyle from "./components/styled/GlobalStyle";
import Home from "./pages/Home/Home";
import Platform from "./pages/Product/Product";
import Root from "./pages/Root";
import Solution from "./pages/Solution/Solution";
import "./theme/variables.scss";

const App: React.FC = () => {
  return (
    <CookiesProvider>
      <IonApp>
        <GlobalStyle />
        <BrowserRouter>
          <Switch>
            {/* <Route path={`/signin`} render={() => <Redirect to='/app/dashboard' />}  /> */}
            <Route path="/home" component={Home} />
            <Route path={`/platform`} component={Platform} />
            <Route path={`/solutions`} component={Solution} />
            <Route path="/app" render={() => <Root path="/app" />} />
            <Route render={() => <Redirect to="/home" />} />
          </Switch>
        </BrowserRouter>
      </IonApp>
    </CookiesProvider>
  );
};

export default App;
