import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { NavLink } from "react-router-dom";
// import logo from "../../assets/logo/j-arc-logo-blue.svg";
import logo from "../../assets/logo/Vist .ai.svg";
import "./HeaderIconstyle.css";
import { HeaderPage } from "./Headerstyle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "flex-end",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      display: "flex",
      flexDirection: "column",
      alignItems: " flex-start",
      padding: "41px 20px 69px",
      borderBottomLeftRadius: "20px",
      width: "253px",
      Height: "333px",
    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <HeaderPage>
        <div className="container wide header-container">
          <div className="header">
            <div className="headerlogocontainer">
              {
                <a href="/home">
                  <img src={logo} alt="Vist" />
                </a>
              }
            </div>
            {/* Desktop view */}
            <div className="desktopview d-flex ion-align-items-center">
              <NavLink className="navheader" to="/platform">
                PLATFORM
              </NavLink>
              <NavLink
                className="navheader"
                style={{ marginLeft: "40px" }}
                to="/solutions"
              >
                SOLUTIONS
              </NavLink>
              <NavLink
                className="navheader"
                style={{ marginLeft: "40px" }}
                to="contact"
              >
                CONTACT
              </NavLink>
            </div>

            {/* Mobile view */}
            <div className="mobileview d-flex ion-align-items-center">
              <div>
                <button className="bar-icon" type="button" onClick={handleOpen}>
                  <MenuIcon />
                </button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div className={classes.paper}>
                      <div onClick={handleClose} className="clear-icon">
                        <ClearIcon />
                      </div>
                      <NavLink className="navheader" to="/platform">
                      Platform
                      </NavLink>
                      <span className="line"></span>
                      <NavLink className="navheader" to="/solutions">
                      Solutions
                      </NavLink>
                      <span className="line"></span>
                      <NavLink className="navheader" to="contact">
                      Contact
                      </NavLink>
                    </div>
                  </Fade>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </HeaderPage>
    </div>
  );
};

export default Header;
