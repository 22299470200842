import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonRow,
} from "@ionic/react";
import Button from "@material-ui/core/Button";
import React from "react";
import AiIcon from "../../assets/icon/ai-icon.svg";
import DataIcon from "../../assets/icon/data-icon.svg";
import Experience_icon from "../../assets/icon/experience-icon.svg";
import PublishIcon from "../../assets/icon/publish-icon.svg";
import CardImage1 from "../../assets/images/card_1.jpg";
import CardImage2 from "../../assets/images/card_2.jpg";
import CardImage3 from "../../assets/images/card_3.jpg";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";
import { SolutionPage } from "./Solutionstyle";

const Solution: React.FC = () => {
  return (
    <div className="main">
      <Header />
      <SolutionPage>
        <div className="container block-platform">
          <div className="ion-text-center">
            <img src={DataIcon} alt="Data Icon" className="card-icon" />
            <div
              className="ion-text-center ion-inherit-color md hydrated"
              style={{ margin: "22px" }}
            >
              <span className="span-title-platform">Vist </span>
              <span className="span-title-detail">.data</span>
            </div>
            <p className="text-content">
              Scalable aggregation platform to bring together digital and text
              data from heterogeneous sources.{" "}
            </p>
            <p className="text-content2">
              Recommended for Aggregators, Infrastructure Projects{" "}
            </p>
          </div>
          <div className="line-2"></div>
        </div>
        <div className="container block-vist">

          <IonRow className="pic-card">
            <IonCol size="12" sizeLg="12" >
              <div className="card-block-right  ion-align-items-center ion-justify-content-center">
                <IonCol size="6" >
                  <img
                    src={CardImage1}
                    alt="Tourism Icon"
                    className="card-pic"
                  />
                </IonCol>
                <IonCol size="6" sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                  <div style={{ alignSelf: "end" }}>
                    <IonCardHeader>
                      <IonCardTitle className="ion-text-left cards-title-vist">
                        Support for Formats and Standards
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="ion-text-left cards-content-vist">
                      Built-in pipelines for ingestion of popular data
                      standards: Dublin Core, METS, MODS, Europeana Data Model
                      and support for custom ones
                    </IonCardContent>
                  </div>
                </IonCol>
              </div>
            </IonCol>
            <IonCol size="12" sizeLg="12">
              <div className="card-block-left  ion-align-items-center ion-justify-content-center">
                <div className="desktop-view">
                  <IonCol size="6">
                    <img
                      src={CardImage2}
                      alt="Tourism Icon"
                      className="card-pic"
                    />
                  </IonCol>
                </div>
                <IonCol size="6" sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                  <div style={{ alignSelf: "end" }}>
                    <IonCardHeader>
                      <IonCardTitle className="ion-text-left cards-title-vist">
                        User-friendly UI
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="ion-text-left cards-content-vist">
                      Powerful data dashboard to inspect various formats
                    </IonCardContent>
                  </div>
                </IonCol>
                <div className="mobile-view">
                  <IonCol size="12" sizeLg="6">
                    <img
                      src={CardImage2}
                      alt="Tourism Icon"
                      className="card-pic"
                    />
                  </IonCol>
                </div>
              </div>
            </IonCol>
            <IonCol size="12" sizeLg="12">
              <div className="card-block-right  ion-align-items-center ion-justify-content-center">
                <IonCol size="6" >
                  <img
                    src={CardImage1}
                    alt="Tourism Icon"
                    className="card-pic"
                  />
                </IonCol>
                <IonCol size="6" sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                  <div style={{ alignSelf: "end" }}>
                    <IonCardHeader>
                      <IonCardTitle className="ion-text-left cards-title-vist">
                        Scalable Infrastructure, headless CMS
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="ion-text-left cards-content-vist">
                      Scalable cloud infrastructure able to support millions
                      of data objects based on a mature Headless CMS
                      technology
                    </IonCardContent>
                  </div>
                </IonCol>
              </div>
            </IonCol>
            <IonCol size="12" sizeLg="12">
              <div className="card-block-left  ion-align-items-center ion-justify-content-center">
                <div className="desktop-view">
                  <IonCol size="6">
                    <img
                      src={CardImage3}
                      alt="Tourism Icon"
                      className="card-pic"
                    />
                  </IonCol>
                </div>
                <IonCol size="6" sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                  <div style={{ alignSelf: "end" }}>
                    <IonCardHeader>
                      <IonCardTitle className="ion-text-left cards-title-vist">
                        Integrated IIIF-compatible media service
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="ion-text-left cards-content-vist">
                      Transforming all incoming digital assets to IIIF
                      industry standard
                    </IonCardContent>
                  </div>
                </IonCol>
                <div className="mobile-view">
                  <IonCol size="12" sizeLg="6">
                    <img
                      src={CardImage2}
                      alt="Tourism Icon"
                      className="card-pic"
                    />
                  </IonCol>
                </div>
              </div>
            </IonCol>
            <IonCol size="12" sizeLg="12">
              <div className="card-block-right  ion-align-items-center ion-justify-content-center">
                <IonCol size="6" >
                  <img
                    src={CardImage1}
                    alt="Tourism Icon"
                    className="card-pic"
                  />
                </IonCol>
                <IonCol size="6" sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                  <div style={{ alignSelf: "end" }}>
                    <IonCardHeader>
                      <IonCardTitle className="ion-text-left cards-title-vist">
                        Developer API
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className="ion-text-left cards-content-vist">
                      Developer-friendly API to access and search aggregated
                      records
                    </IonCardContent>
                  </div>
                </IonCol>
              </div>
            </IonCol>
          </IonRow>
        </div>
        <div className="CTA d-flex ion-align-items-center ion-justify-content-center book-demo-flex">
          <h2 className="ion-no-margin text-color-white bookDemoText">
            Let us show you what we've got!
          </h2>
          <Button className="bookDemoButton" variant="outlined" color="primary">
            BOOK A DEMO
          </Button>
        </div>
        <div className="container block-platform">
          <div >
            <div
              className="ion-text-center ion-inherit-color md hydrated "
            >
              <span className="card-text">Additional Platforms </span>
            </div>
            <IonRow className="card-spacing">
              <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4">
                <IonCard className="d-flex card-size">
                  <img
                    src={AiIcon}
                    alt="Data Icon"
                    className="card-icon"
                  />
                  <IonCardHeader>
                    <IonCardTitle className="ion-text-center">
                      <span className="card-title-platform">Vist </span>{" "}
                      <span className="card-title-detail">.AI</span>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className="ion-text-center card-content">
                    AI services to enhance your data
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4" sizeSm="12" sizeXs="12">
                <IonCard className="d-flex card-size">
                  <img
                    src={Experience_icon}
                    alt="Ai Icon"
                    className="card-icon"
                  />
                  <IonCardHeader>
                    <IonCardTitle className="ion-text-center">
                      <span className="card-title-platform">Vist </span>{" "}
                      <span className="card-title-detail">.experience</span>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className="card-content">
                    Integrated editors to develop various storytelling formats
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4">
                <IonCard className="d-flex card-size">
                  <img
                    src={PublishIcon}
                    alt="Publish Icon"
                    className="card-icon"
                  />
                  <IonCardHeader>
                    <IonCardTitle className="ion-text-center">
                      <span className="card-title-platform">Vist </span>{" "}
                      <span className="card-title-detail">.publish</span>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent className="ion-text-center card-content">
                    Publish and embed the results on leading platforms, apps and
                    websites
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </div>
        </div>
      </SolutionPage>
      <Footer />
    </div>
  );
};

export default Solution;
